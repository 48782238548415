import { converCategoryIdIntoUsername } from '@data/allCategories';
import { postUserSearchActivity } from '@layouts/search/api';
import { LocoDeepLinkPage, SearchSuggestionResType } from '@layouts/search/common/type';
import { getSourceEventProperties } from '@layouts/stream/helpers';
import { Amplitude } from '@modules/analytics';
import { search_query_web } from '@modules/analytics/types/constants';
import { search_query_web_props } from '@modules/analytics/types/eventPropsTypes';
import { IAutoSuggestionInfo, useAppStore } from '@stores/appStore';
import { getURLParams } from '@utils/getUrlParams';
import { useRouter } from 'next/router';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const AutoSuggestionSkeleton = () => {
  return _jsxs("div", {
    className: "grid grid-cols-1 gap-y-3 mt-5 md:mt-0 pl-5 pr-3 md:pl-4 md:pr-4",
    children: [[1, 2].map(item => _jsxs("div", {
      className: "flex gap-x-2",
      children: [_jsx("div", {
        className: "h-12 w-12 bg-[#262626] rounded-full animate-pulse"
      }), _jsxs("div", {
        className: "flex flex-col gap-y-1 justify-center",
        children: [_jsx("span", {
          className: "h-[16px] w-16 bg-[#262626] rounded animate-pulse"
        }), _jsx("span", {
          className: "h-3 w-36 bg-[#262626] rounded animate-pulse"
        })]
      })]
    }, item)), [1, 2, 3, 4].map(item => _jsx("div", {
      className: "bg-[#262626] h-5 w-full rounded-md animate-pulse"
    }, item))]
  });
};
const SearchSuggestion = ({
  type,
  isStreamerVerified,
  isLive = false,
  subText,
  text,
  imgUrl
}: {
  type: 'streamer' | 'category' | 'term';
  isStreamerVerified?: boolean;
  isLive?: boolean;
  text: string;
  subText?: string;
  imgUrl?: string;
}) => {
  if (type === 'term') {
    return _jsxs("div", {
      className: "flex items-center justify-between md:hover:bg-[#262626] pl-5 pr-3 md:pl-4 md:pr-4 md:py-1",
      children: [_jsxs("div", {
        className: "flex items-center gap-x-[6px] pr-4 w-[90%]",
        children: [_jsx("img", {
          src: "/static/images/search/search_icon_suggestion.svg",
          alt: "search",
          height: 16,
          width: 16
        }), _jsx("span", {
          className: "text-14 leading-5 text-left whitespace-nowrap w-11/12 overflow-hidden overflow-ellipsis",
          children: text
        })]
      }), _jsx("img", {
        src: "/static/images/search/right-arrow.svg",
        alt: "right-arrow",
        height: 14,
        width: 14
      })]
    });
  }
  return _jsxs("div", {
    className: "flex items-center justify-between pl-5 pr-3 md:pl-4 md:pr-4",
    children: [_jsxs("div", {
      className: "flex gap-x-2 w-[90%]",
      children: [_jsxs("div", {
        className: "relative max-w-[25%]",
        children: [_jsx("img", {
          src: imgUrl,
          height: 48,
          width: 48,
          className: `min-w-[48px] min-h-[48px] ${isLive ? 'border-2 border-[#E02020]' : ''} ${type === 'streamer' ? 'rounded-full' : ''}`,
          alt: type
        }), isStreamerVerified && type === 'streamer' && _jsx("img", {
          src: '/static/images/search/verified-mark.svg',
          height: 12,
          width: 12,
          className: "absolute bottom-[2px] right-[2px] min-w-[12px] min-h-[12px]",
          alt: 'verified-mark'
        })]
      }), _jsxs("div", {
        className: "flex flex-col justify-center items-start min-w-[75%] text-left",
        children: [_jsxs("div", {
          className: "flex items-center gap-x-2 w-full",
          children: [_jsx("span", {
            className: "text-14 leading-5 max-w-[80%] whitespace-nowrap overflow-hidden overflow-ellipsis",
            children: text
          }), isLive && _jsx("img", {
            src: "/static/images/search/live-tag.svg",
            alt: "live-tag",
            height: 12,
            width: 30
          })]
        }), _jsx("span", {
          className: "text-10 leading-[14px] text-[#999999] w-full whitespace-nowrap overflow-hidden overflow-ellipsis",
          children: subText
        })]
      })]
    }), _jsx("img", {
      src: "/static/images/search/right-arrow.svg",
      alt: "right-arrow",
      height: 14,
      width: 14
    })]
  });
};
const AutoSuggestionList = ({
  data,
  setShowTrendingSection,
  handleSearchInputChange,
  device,
  searchQuery
}: {
  data: SearchSuggestionResType | [];
  setShowTrendingSection: (value: boolean) => void;
  handleSearchInputChange: (value: string) => void;
  device: 'mobile' | 'web';
  searchQuery: string;
}) => {
  const router = useRouter();
  const setSearchTrigger = useAppStore(state => state.setSearchTrigger);
  const setSearchTriggerPageInfo = useAppStore(state => state.setSearchTriggerPageInfo);
  const pageInfo = useAppStore(state => state.pageInfo);
  const {
    sourceTrackingInfo
  } = useAppStore(state => state);
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  const updateSearchTriggerPageInfo = ({
    autosuggest_clicked,
    autosuggest_position,
    autosuggest_shown,
    clicked_query_type
  }: IAutoSuggestionInfo) => {
    setSearchTrigger('keyboard');
    if (device === 'mobile') {
      Amplitude.logEvent(search_query_web, {
        query: searchQuery,
        query_source_type: 'keyboard',
        autosuggest_clicked,
        autosuggest_position,
        autosuggest_shown,
        clicked_query_type,
        ...sourceEventProperties
      });
    } else {
      Amplitude.logEvent(search_query_web, (({
        query: searchQuery,
        query_source_type: 'keyboard',
        search_webpage: pageInfo?.page,
        search_webpage_category_id: pageInfo?.categoryId,
        search_webpage_category_name: pageInfo?.categoryName,
        search_webpage_streamer_id: pageInfo?.streamerId,
        search_webpage_streamer_name: pageInfo?.streamerName,
        search_webpage_video_id: pageInfo?.videoId,
        search_webpage_video_type: pageInfo?.videoType,
        web_page_type: 'webpage_search',
        autosuggest_clicked,
        autosuggest_position,
        autosuggest_shown,
        clicked_query_type,
        ...sourceEventProperties
      } as search_query_web_props) as any));
    }
  };
  return _jsx("div", {
    className: "grid grid-cols-1 gap-y-3 mt-5 md:mt-0",
    children: data.map((item, autosuggestionItemIndex) => {
      return item.content?.map((ele, index) => {
        const deepLinkParams = getURLParams(ele.component.data.click_action.deep_link);
        const searchType = ele.component.analytics?.type;
        if (parseInt(deepLinkParams['type']) === LocoDeepLinkPage.STREAMER_PROFILE && deepLinkParams['streamer_uid']) {
          const streamerId = deepLinkParams['streamer_uid'];
          return _jsx("button", {
            onClick: async () => {
              device === 'web' && setShowTrendingSection(false);
              updateSearchTriggerPageInfo({
                autosuggest_shown: true,
                autosuggest_clicked: true,
                autosuggest_position: autosuggestionItemIndex + 1,
                clicked_query_type: 'streamer'
              });
              await postUserSearchActivity({
                type: 'streamer',
                search_query: streamerId
              });
              router.push(`/streamers/${(item.content[0].component.data.title.data.toLocaleLowerCase() as string)}`);
            },
            children: _jsx(SearchSuggestion, {
              type: "streamer",
              text: ele.component.data.title.data ?? '',
              subText: ele.component.data.sub_title?.data ?? '',
              isStreamerVerified: !!ele.component.data.title.is_verified,
              imgUrl: ele.component.data.thumbnail?.data,
              isLive: !!ele.component.data.title.is_live
            })
          }, index);
        }
        if (parseInt(deepLinkParams['type']) === LocoDeepLinkPage.GAME_DETAIL && deepLinkParams['game_uid']) {
          const categoryId = deepLinkParams['game_uid'];
          return _jsx("button", {
            onClick: async () => {
              device === 'web' && setShowTrendingSection(false);
              updateSearchTriggerPageInfo({
                autosuggest_shown: true,
                autosuggest_clicked: true,
                autosuggest_position: autosuggestionItemIndex + 1,
                clicked_query_type: 'category'
              });
              await postUserSearchActivity({
                type: 'category',
                search_query: categoryId
              });
              router.push(`/categories/${converCategoryIdIntoUsername(categoryId)}`);
            },
            children: _jsx(SearchSuggestion, {
              type: "category",
              text: ele.component.data.title?.data ?? '',
              subText: ele.component.data.sub_title?.data ?? '',
              imgUrl: ele.component.data.thumbnail?.data
            })
          }, index);
        }
        if (deepLinkParams['search_query']) {
          const searchTerm = deepLinkParams['search_query'];
          return _jsx("button", {
            onClick: async () => {
              setShowTrendingSection(false);
              handleSearchInputChange(searchTerm);
              setSearchTriggerPageInfo({
                autosuggest_shown: searchType ? true : false,
                autosuggest_clicked: searchType ? true : false,
                autosuggest_position: searchType ? autosuggestionItemIndex + 1 : 'none',
                clicked_query_type: 'term'
              });
              await postUserSearchActivity({
                type: 'term',
                search_query: searchTerm
              });
              router.push(`/search/?query=${searchTerm}`);
            },
            children: _jsx(SearchSuggestion, {
              type: "term",
              text: searchTerm
            })
          }, index);
        }
      });
    })
  });
};
export { AutoSuggestionList, AutoSuggestionSkeleton, SearchSuggestion };