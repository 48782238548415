import Loader from '@components/loader';
import { converCategoryIdIntoUsername } from '@data/allCategories';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import { deleteUserSearchActivity, getNewTrendingSearch, getUserSearchActivity, postUserSearchActivity } from '@layouts/search/api';
import { HOME_COMPONENT_ID, HOME_ROW_TYPE, LocoDeepLinkPage, TSearchTrending } from '@layouts/search/common/type';
import { getSourceEventProperties } from '@layouts/stream/helpers';
import { Amplitude } from '@modules/analytics';
import { search_history_delete, search_query_web } from '@modules/analytics/types/constants';
import { search_query_web_props } from '@modules/analytics/types/eventPropsTypes';
import { useAppStore } from '@stores/appStore';
import { getURLParams } from '@utils/getUrlParams';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const NewTrendingList = ({
  trendingSearchData,
  setTrendingSearchData,
  trendingSearchLazyLoading,
  setTrendingSearchLazyLoading,
  trendingSearchOffset,
  setTrendingSearchOffset,
  setShowTrendingSection,
  device
}: {
  trendingSearchData: TSearchTrending[];
  setTrendingSearchData: (value: TSearchTrending[]) => void;
  trendingSearchLazyLoading: boolean;
  setTrendingSearchLazyLoading: (value: boolean) => void;
  trendingSearchOffset: string | null;
  setTrendingSearchOffset: (value: string | null) => void;
  setShowTrendingSection: (value: boolean) => void;
  device: 'web' | 'mobile';
}) => {
  const [recentSearchData, setRecentSearchData] = useState<TSearchTrending[]>([]);
  const [recentSearchLoading, setRecentSearchLoading] = useState(true);
  useEffect(() => {
    setRecentSearchLoading(true);
    (async () => {
      const res = await getUserSearchActivity();
      setRecentSearchData(res.results);
      setRecentSearchLoading(false);
    })();
  }, []);
  const handleLoadMore = async () => {
    setTrendingSearchLazyLoading(true);
    const trendingSearchDataRes = await getNewTrendingSearch({
      offset: trendingSearchOffset
    });
    setTrendingSearchData([...trendingSearchData, ...trendingSearchDataRes.results]);
    setTrendingSearchOffset(trendingSearchDataRes.next);
    setTrendingSearchLazyLoading(false);
  };
  const {
    ref: trendingSearchScrollRef
  } = useInfiniteScroll({
    loading: trendingSearchLazyLoading,
    hasNextPage: !!trendingSearchOffset,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 0px 0px'
  });
  const updateRecentSearchDataAfterDelete = (component_id: string) => {
    const updatedData = recentSearchData.map(section => {
      const updatedContent = section.content.filter(item => item.component.component_uid !== component_id);
      return {
        ...section,
        content: updatedContent
      };
    });
    setRecentSearchData(updatedData);
  };
  return _jsxs("div", {
    className: `${device === 'web' ? 'pt-2' : 'pt-4'}`,
    children: [recentSearchLoading ? _jsx(SearchTrendingSkeleton, {
      device: device
    }) : recentSearchData.length ? recentSearchData.map((item, index) => _jsx(TrendingSearchQuery, {
      data: item,
      device: device,
      updateDataAfterDelete: component_id => updateRecentSearchDataAfterDelete(component_id),
      setShowTrendingSection: setShowTrendingSection
    }, index)) : null, _jsxs(_Fragment, {
      children: [trendingSearchData.map(trendingType => {
        switch (trendingType.row_type) {
          case HOME_ROW_TYPE.TEST:
            return _jsx(TrendingSearchQuery, {
              data: trendingType,
              setShowTrendingSection: setShowTrendingSection,
              device: device
            });
          case HOME_ROW_TYPE.SMALL_WIDTH:
            return _jsx(TrendingSearchSmallWidthSection, {
              data: trendingType,
              setShowTrendingSection: setShowTrendingSection,
              device: device
            });
          default:
            return null;
        }
      }), trendingSearchLazyLoading || trendingSearchOffset ? _jsx("div", {
        className: "flex justify-center w-full",
        ref: trendingSearchScrollRef,
        children: _jsx(Loader, {
          style: {
            height: '20px',
            width: '20px',
            border: '2px solid grey',
            borderTop: '2px solid black'
          }
        })
      }) : null]
    })]
  });
};
const SearchTrendingSkeleton = ({
  device
}: {
  device: 'web' | 'mobile';
}) => {
  return _jsxs("div", {
    className: "mx-4 mb-4",
    children: [_jsx("div", {
      className: "h-[21px] mb-2 w-[100px] bg-[#262626] animate-pulse"
    }), _jsx("div", {
      className: "flex flex-wrap gap-x-2 gap-y-2",
      children: [...new Array(device === 'web' ? 5 : 4)].map((_, index) => _jsx("div", {
        className: "h-[31px] w-[120px] rounded bg-[#262626] animate-pulse"
      }, index))
    })]
  });
};
const TrendingSearchQuery = ({
  data,
  updateDataAfterDelete,
  setShowTrendingSection,
  device
}: {
  data: TSearchTrending;
  updateDataAfterDelete?: (component_id: string) => void;
  setShowTrendingSection: (value: boolean) => void;
  device: 'web' | 'mobile';
}) => {
  if (!data.content.length) return null;
  const getSearchType = (deepLinkParams: {
    [id: string]: string;
  }) => {
    const searchType = {
      type: '',
      search_query: ''
    };
    if (parseInt(deepLinkParams['type']) === LocoDeepLinkPage.STREAMER_PROFILE && deepLinkParams['streamer_uid']) {
      searchType.type = 'streamer';
      searchType.search_query = deepLinkParams['streamer_uid'];
    } else if (parseInt(deepLinkParams['type']) === LocoDeepLinkPage.GAME_DETAIL && deepLinkParams['game_uid']) {
      searchType.type = 'category';
      searchType.search_query = deepLinkParams['game_uid'];
    } else if (deepLinkParams['search_query']) {
      searchType.type = 'term';
      searchType.search_query = deepLinkParams['search_query'];
    }
    return searchType;
  };
  const handleSearchQueryDelete = (component_uid: string) => {
    updateDataAfterDelete?.(component_uid);
  };
  return _jsxs("div", {
    className: "mx-4 mb-4",
    children: [_jsx("p", {
      className: "text-14 font-semibold text-[#EDEDEF] mb-2",
      children: data.title
    }), _jsx("div", {
      className: "flex flex-wrap gap-x-2 gap-y-2",
      children: data.content.map((query, index) => {
        const deepLinkParams = getURLParams(query.component.data.click_action.deep_link);
        const searchType = getSearchType(deepLinkParams);
        if (!searchType.type) return null;
        return _jsx(TrendingSearchQueryUI, {
          searchQuery: query.component.data.title.data,
          startImgUrl: query.component.data.start_image?.data ?? '',
          isStartImgRounded: !!query.component.data.start_image?.is_circular,
          borderColor: query.component.data.rim_color?.data ?? '#262626',
          bgColor: query.component.data.bg_color?.data ?? '#1A1A1A',
          endImgUrl: query.component.data.end_image?.data ?? '',
          searchType: searchType,
          sectionTitle: query.component.analytics?.section_title ?? '',
          setShowTrendingSection: setShowTrendingSection,
          handleSearchQueryDelete: () => handleSearchQueryDelete(query.component.component_uid),
          device: device
        }, index);
      })
    })]
  });
};
const TrendingSearchQueryUI = ({
  searchQuery,
  startImgUrl,
  isStartImgRounded,
  endImgUrl,
  borderColor,
  bgColor,
  searchType,
  sectionTitle,
  setShowTrendingSection,
  handleSearchQueryDelete,
  device
}: {
  searchQuery: string;
  startImgUrl: string;
  isStartImgRounded: boolean;
  endImgUrl?: string;
  borderColor: string;
  bgColor: string;
  searchType: {
    type: string;
    search_query: string;
  };
  sectionTitle: string;
  device: 'web' | 'mobile';
  setShowTrendingSection: (value: boolean) => void;
  handleSearchQueryDelete?: () => void;
}) => {
  const router = useRouter();
  const isRecentSearch = !!endImgUrl; //end image url only comes in recent search

  const setSearchTrigger = useAppStore(state => state.setSearchTrigger);
  const setSearchTriggerPageInfo = useAppStore(state => state.setSearchTriggerPageInfo);
  const pageInfo = useAppStore(state => state.pageInfo);
  const {
    sourceTrackingInfo
  } = useAppStore(state => state);
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  const updateSearchTriggerPageInfo = ({
    clicked_query_type
  }: {
    clicked_query_type: string;
  }) => {
    if (device === 'mobile') {
      Amplitude.logEvent(search_query_web, {
        query: searchQuery,
        query_source_type: 'trending',
        clicked_query_type,
        section_title: sectionTitle,
        autosuggest_shown: false,
        autosuggest_clicked: false,
        autosuggest_position: 'none',
        ...sourceEventProperties
      });
    } else {
      Amplitude.logEvent(search_query_web, (({
        query: searchQuery,
        query_source_type: 'trending',
        web_page_type: 'webpage_search',
        clicked_query_type,
        section_title: sectionTitle,
        autosuggest_shown: false,
        autosuggest_clicked: false,
        autosuggest_position: 'none',
        search_webpage: pageInfo?.page,
        search_webpage_category_id: pageInfo?.categoryId,
        search_webpage_category_name: pageInfo?.categoryName,
        search_webpage_streamer_id: pageInfo?.streamerId,
        search_webpage_streamer_name: pageInfo?.streamerName,
        search_webpage_video_id: pageInfo?.videoId,
        search_webpage_video_type: pageInfo?.videoType,
        ...sourceEventProperties
      } as search_query_web_props) as any));
    }
  };
  const handleSearchQueryClicked = async () => {
    await postUserSearchActivity(searchType);
    if (device === 'web' && searchType.type !== 'term') {
      setShowTrendingSection(false);
    }
    if (searchType.type === 'streamer') {
      updateSearchTriggerPageInfo({
        clicked_query_type: 'streamer'
      });
      router.push(`/streamers/${searchQuery.toLocaleLowerCase()}`);
    } else if (searchType.type === 'category') {
      updateSearchTriggerPageInfo({
        clicked_query_type: 'category'
      });
      router.push(`/categories/${converCategoryIdIntoUsername(searchType.search_query)}`);
    } else if (searchType.type === 'term') {
      setShowTrendingSection(false);
      setSearchTrigger('trending');
      setSearchTriggerPageInfo({
        autosuggest_shown: false,
        autosuggest_clicked: false,
        autosuggest_position: 'none',
        clicked_query_type: 'term',
        section_title: sectionTitle
      });
      router.push(`/search/?query=${searchType.search_query}`);
    }
  };
  const handleOnDelete = async () => {
    Amplitude.logEvent(search_history_delete, {
      clicked_query_type: searchType.type,
      deleted_query: searchQuery
    });
    await deleteUserSearchActivity(searchType);
    handleSearchQueryDelete?.();
  };
  const truncateText = ({
    text,
    limit
  }: {
    text: string;
    limit: number;
  }) => {
    if (text.length <= limit) return text;
    return text.slice(0, limit) + '...';
  };
  return _jsxs("div", {
    className: "px-2 py-1 rounded flex items-center justify-center gap-x-2",
    style: {
      background: bgColor,
      border: `1px solid ${borderColor}`
    },
    children: [_jsxs("button", {
      onClick: handleSearchQueryClicked,
      className: "flex items-center gap-x-1",
      children: [_jsx("img", {
        src: searchType.type === 'term' ? startImgUrl ? startImgUrl : isRecentSearch ? '/static/images/search/recent_term_search.svg' : '/static/images/search/trending_term_search.svg' : startImgUrl,
        alt: "search-icon",
        height: 16,
        width: 16,
        className: `${isStartImgRounded ? 'rounded-full' : ''}
            ${searchType.type === 'term' ? '' : 'bg-gray-700'}
            `
      }), _jsx("span", {
        className: "text-14",
        children: truncateText({
          text: searchQuery,
          limit: 12
        })
      })]
    }), endImgUrl ? _jsx("button", {
      onClick: handleOnDelete,
      children: _jsx("img", {
        src: endImgUrl ?? '/static/images/search/cross.svg',
        alt: "cross",
        height: 16,
        width: 16
      })
    }) : null]
  });
};
const TrendingSearchSmallWidthSection = ({
  data,
  device,
  setShowTrendingSection
}: {
  data: TSearchTrending;
  device: 'web' | 'mobile';
  setShowTrendingSection: (value: boolean) => void;
}) => {
  const router = useRouter();
  const setSearchTrigger = useAppStore(state => state.setSearchTrigger);
  const pageInfo = useAppStore(state => state.pageInfo);
  const {
    sourceTrackingInfo
  } = useAppStore(state => state);
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  if (!data.content.length) return null;
  const updateSearchTriggerPageInfo = ({
    itemTitle,
    sectionTitle,
    clickedQueryType
  }: {
    itemTitle: string;
    sectionTitle: string;
    clickedQueryType: string;
  }) => {
    setSearchTrigger('keyboard');
    if (device === 'mobile') {
      Amplitude.logEvent(search_query_web, {
        query: itemTitle,
        query_source_type: 'trending',
        autosuggest_clicked: false,
        autosuggest_shown: false,
        autosuggest_position: 'none',
        clicked_query_type: clickedQueryType,
        section_title: sectionTitle,
        ...sourceEventProperties
      });
    } else {
      Amplitude.logEvent(search_query_web, (({
        query: itemTitle,
        query_source_type: 'trending',
        autosuggest_clicked: false,
        autosuggest_shown: false,
        autosuggest_position: 'none',
        clicked_query_type: clickedQueryType,
        section_title: sectionTitle,
        search_webpage: pageInfo?.page,
        search_webpage_category_id: pageInfo?.categoryId,
        search_webpage_category_name: pageInfo?.categoryName,
        search_webpage_streamer_id: pageInfo?.streamerId,
        search_webpage_streamer_name: pageInfo?.streamerName,
        search_webpage_video_id: pageInfo?.videoId,
        search_webpage_video_type: pageInfo?.videoType,
        web_page_type: 'webpage_search',
        ...sourceEventProperties
      } as search_query_web_props) as any));
    }
  };
  const handleOnClick = async ({
    deepLinkParams,
    itemTitle,
    sectionTitle
  }: {
    deepLinkParams: {
      [id: string]: string;
    };
    itemTitle: string;
    sectionTitle: string;
  }) => {
    if (device === 'web') {
      setShowTrendingSection(false);
    }
    if (parseInt(deepLinkParams['type']) === LocoDeepLinkPage.GAME_DETAIL && deepLinkParams['game_uid']) {
      updateSearchTriggerPageInfo({
        itemTitle,
        sectionTitle,
        clickedQueryType: 'category'
      });
      const categoryId = deepLinkParams['game_uid'];
      await postUserSearchActivity({
        type: 'category',
        search_query: categoryId
      });
      router.push(`/categories/${converCategoryIdIntoUsername(categoryId)}`);
    }
  };
  return _jsxs("div", {
    className: "mx-4 mb-4",
    children: [_jsx("p", {
      className: "text-14 font-semibold text-[#EDEDEF] mb-2",
      children: data.title
    }), _jsx("div", {
      className: "search-trending-categories flex items-start whitespace-nowrap overflow-x-scroll gap-x-4 border-opacity-100",
      children: data.content.map((item, index) => {
        if (item.component.id !== HOME_COMPONENT_ID.SQUARE_IMAGE_TITLE_SUBTITLE_CTA) return null;
        const deepLinkParams = getURLParams(item.component.data.click_action.deep_link);
        const itemTitle = item.component.data.title.data;
        const sectionTitle = item.component.analytics?.section_title ?? '';
        return _jsx("button", {
          onClick: () => handleOnClick({
            deepLinkParams,
            itemTitle,
            sectionTitle
          }),
          children: _jsxs("div", {
            className: "flex flex-col gap-y-1 max-w-[48px]",
            children: [_jsx("img", {
              src: item.component.data.thumbnail?.data,
              alt: item.component.data.title.data,
              height: 48,
              width: 48,
              className: "min-w-[48px] min-h-[48px]"
            }), _jsx("p", {
              className: "text-10 leading-5 whitespace-normal",
              children: itemTitle
            })]
          })
        }, index);
      })
    })]
  });
};
export default NewTrendingList;