function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import FadeEffect from '@components/FadeEffectElement';
import { VIDEO_PLAYER_ID } from '@components/VideoPlayer/constants';
import { useControlDisplayStore } from '@components/VideoPlayer/store/useControlDisplayStore';
import { usePlayerStore } from '@components/VideoPlayer/store/usePlayerStore';
import { enterFullScreen, exitFullScreen, getFullScreen } from '@components/VideoPlayer/Utils/utils';
import { useAppStore } from '@stores/appStore';
import { useStreamStore } from '@stores/streamStore';
import React, { useEffect } from 'react';
import * as workerTimers from 'worker-timers';
import PlayerErrorComp from '../Common/PlayerError';
import PlayerSpinner from '../Common/PlayerSpinner';
import StreamStartingSoon from '../Common/StreamStartingSoon';
import MiniPlayerControls from './MiniPlayerControls';
import PlayerControls from './PlayerControls';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const {
  setIsFullScreen
} = useStreamStore.getState();
const {
  setControlSectionVisibleTimer,
  resetControlSectionVisiblity
} = useControlDisplayStore.getState();
var _ref = process.env.NODE_ENV === "production" ? {
  name: "o9vqif",
  styles: "position:absolute;z-index:10;height:100%;width:100%"
} : {
  name: "dosrxp-ControlSheetSection",
  styles: "position:absolute;z-index:10;height:100%;width:100%;label:ControlSheetSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "lr2i0y",
  styles: "position:absolute;height:100%;width:100%;--tw-bg-opacity:1;background-color:rgb(0 0 0 / var(--tw-bg-opacity));opacity:0.4"
} : {
  name: "1eydwj8-ControlSheetSection",
  styles: "position:absolute;height:100%;width:100%;--tw-bg-opacity:1;background-color:rgb(0 0 0 / var(--tw-bg-opacity));opacity:0.4;label:ControlSheetSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "o9vqif",
  styles: "position:absolute;z-index:10;height:100%;width:100%"
} : {
  name: "dosrxp-ControlSheetSection",
  styles: "position:absolute;z-index:10;height:100%;width:100%;label:ControlSheetSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "w093qa",
  styles: "position:absolute;z-index:10;height:100%;width:100%;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms"
} : {
  name: "x79ka1-ControlSheetSection",
  styles: "position:absolute;z-index:10;height:100%;width:100%;transition-property:all;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;label:ControlSheetSection;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const ControlSheetSection = (props: {
  children?: React.ReactNode;
}) => {
  const isFullScreen = useStreamStore(state => state.isFullScreen);
  const isLoginModalOpen = useAppStore(state => state.isLoginModalOpen);
  const isHoverOnContainer = useControlDisplayStore(state => state.isHoverOnContainer);
  const isSettingModalOpen = useControlDisplayStore(state => state.isSettingModalOpen);
  const isControlSectionVisible = useControlDisplayStore(state => state.isControlSectionVisible);
  const controlSectionVisibleTimer = useControlDisplayStore(state => state.controlSectionVisibleTimer);
  const isLoading = usePlayerStore(state => state.isLoading);
  const isBuffering = useStreamStore(state => state.isBuffering);
  const isPortalOpen = usePlayerStore(state => state.isPortalOpen);
  const playerError = usePlayerStore(state => state.playerError);
  const hasPlayerError = !!playerError;
  useEffect(() => {
    // const isCurrentyInFullScreen = getFullScreen()
    const videoPlayer = document.getElementById(VIDEO_PLAYER_ID);
    if (!videoPlayer) {
      return;
    }
    if (isFullScreen) {
      enterFullScreen(videoPlayer);
      // Checking if user exit fullscreen by pressing ESC
      const timer = workerTimers.setInterval(() => {
        const isStillFullScreen = getFullScreen();
        if (!isStillFullScreen) {
          // Timer removed when isFullScreen is true
          setIsFullScreen(false);
        }
      }, 1000);
      return () => {
        workerTimers.clearInterval(timer);
      };
    } else {
      exitFullScreen();
    }
  }, [isFullScreen]);
  useEffect(() => {
    if (isLoginModalOpen && isFullScreen) {
      setIsFullScreen(false);
    }
  }, [isFullScreen, isLoginModalOpen]);
  useEffect(() => {
    if (isHoverOnContainer || isSettingModalOpen) {
      resetControlSectionVisiblity();
    } else if (!isHoverOnContainer && isControlSectionVisible) {
      const timer = workerTimers.setTimeout(() => {
        setControlSectionVisibleTimer(controlSectionVisibleTimer - 0.5);
      }, 500);
      return () => {
        workerTimers.clearTimeout(timer);
      };
    }
  }, [isSettingModalOpen, isHoverOnContainer, isControlSectionVisible, controlSectionVisibleTimer]);
  return _jsxs(React.Fragment, {
    children: [props.children, !hasPlayerError && isLoading ? _jsx("div", {
      css: _ref,
      children: _jsx(StreamStartingSoon, {})
    }) :
    // Showing in-stream loader
    _jsxs(_Fragment, {
      children: [!hasPlayerError && isBuffering ? _jsx("div", {
        css: _ref2
      }) : null, _jsx(PlayerSpinner, {
        isVisible: !hasPlayerError && isBuffering
      })]
    }), _jsx(FadeEffect, {
      isOpen: hasPlayerError,
      children: ({
        isVisible
      }) => _jsxs("div", {
        css: _ref3,
        children: [_jsx("div", {
          css: _ref4,
          style: {
            background: isVisible ? 'rgba(0,0,0)' : 'rgba(0,0,0)',
            visibility: isVisible ? 'visible' : 'hidden',
            opacity: isVisible ? 0.8 : 0
          }
        }), playerError && _jsx(PlayerErrorComp, {
          playerError: playerError
        })]
      })
    }), isPortalOpen ? _jsx(_Fragment, {
      children: _jsx(FadeEffect, {
        isOpen: hasPlayerError || isControlSectionVisible,
        children: ({
          isVisible
        }) => {
          return _jsx("div", {
            className: "player-controls-container mini-player",
            style: {
              background: isVisible ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0)',
              opacity: !!isVisible ? 1 : 0,
              visibility: !!isVisible ? 'visible' : 'hidden'
            },
            children: _jsx(MiniPlayerControls, {})
          });
        }
      })
    }) : _jsx(_Fragment, {
      children: _jsx(FadeEffect, {
        isOpen: hasPlayerError || isControlSectionVisible,
        children: ({
          isVisible
        }) => {
          return _jsx("div", {
            className: "player-controls-container",
            style: {
              transform: isVisible ? 'translateY(0)' : 'translateY(50%)',
              opacity: !!isVisible ? 1 : 0,
              visibility: !!isVisible ? 'visible' : 'hidden'
            },
            children: _jsx(PlayerControls, {})
          });
        }
      })
    })]
  });
};
export default ControlSheetSection;