import { playerLogs } from '@components/VideoPlayer/Utils/helper';
import { MeInterface, STREAMER_TYPE, StreamInterface } from '@customTypes/index';
import { unicornEvents } from '@functions/stream';
// import { getUtmCampaign } from '@functions/utmCampaign'
import { Amplitude } from '@modules/analytics';
import { chat_stream, comment_vod } from '@modules/analytics/types/constants';
import { comment_vod_props } from '@modules/analytics/types/eventPropsTypes';
import { chat_stream_props } from '@modules/analytics/types/eventPropsTypes';
import { leaderboardDetailsType, StateDataType } from '@modules/instreamLeaderboard/normalLeaderboard/utils/types';
import { IPageInfo, pageType, useAppStore } from '@stores/appStore';
import { useAuthStore } from '@stores/authStore';
import { useProfileStore } from '@stores/profileStore';
import { useStreamStore } from '@stores/streamStore';
import { fetchEventData, updateMetric } from '@stores/usePlayerEvents';
import { isProduction } from '@utils/platform';
import dayjs from 'dayjs';
import isMobile from 'is-mobile';
import { getSourceEventProperties, GetSourceEventPropertiesType } from './helpers';
import { filterDesktopSpecificFields } from './unicornLogFun';
const dLog = new playerLogs();
const IS_STAGING = process.env.IS_STAGING ? JSON.parse(process.env.IS_STAGING) : false;
type SourceEventPropertiesType = {
  source_page_name: string | undefined;
  source_sidenav: string;
  source_page_streamer_name: string | undefined;
  source_page_streamer_id: string | undefined;
  source_page_category_name: string | undefined;
  source_page_category_id: string | undefined;
  source_page_video_id: string | undefined;
};
type LogChatEvent = {
  stream: StreamInterface;
  chatCount: number;
  commentCount: number;
  source_name: string;
  replyCount: number;
  sourceEventProperties: SourceEventPropertiesType;
};
export const logChatEvent = ({
  stream,
  chatCount,
  commentCount,
  replyCount,
  source_name,
  sourceEventProperties
}: LogChatEvent): void => {
  const pageInfo = useAppStore.getState().pageInfo;
  if (stream.status === 20) {
    if (chatCount > 0) {
      Amplitude.logEvent(chat_stream, (({
        chat_sent: chatCount,
        ...sourceEventProperties,
        category_id: stream.game_uid,
        category_name: stream.game_name,
        streamer_id: stream.streamer.user_uid,
        video_id: stream.uid,
        streamer_name: stream.streamer.user_uid,
        video_tags: stream.tags,
        web_page_type: pageInfo.page,
        source_name
      } as chat_stream_props) as any));
    }
  } else {
    if (commentCount + replyCount > 0) {
      Amplitude.logEvent(comment_vod, (({
        ...sourceEventProperties,
        comment_count: commentCount,
        reply_count: replyCount,
        category_id: stream.game_uid,
        category_name: stream.game_name,
        streamer_id: stream.streamer.user_uid,
        video_id: stream.uid,
        streamer_name: stream.streamer.user_uid,
        video_tags: stream.tags,
        web_page_type: pageInfo.page,
        source_name
      } as comment_vod_props) as any));
    }
  }
};
type ChatToggleCountType = {
  chatCollapsed: number;
  chatReOpened: number;
};
type LogWatchVideoEvent = {
  stream: StreamInterface | undefined;
  buffered_time_in_seconds: number;
  duration: number;
  seekBarCount?: number;
  theatreModeUsed: boolean;
  theatreModeWatchCount: number;
  fullscreenModeUsed: boolean;
  fullscreenModeWatchCount: number;
  chatToggleCount: ChatToggleCountType;
  sourceEventProperties: GetSourceEventPropertiesType;
  streamJoinTime: number;
  commonEvents: any;
  gold_earned: number;
  times_buffered: number;
  playerDescriptionExpandCount: number;
  stream_web_page_type?: pageType;
};
export const logSeekBarUsedEvent = ({
  seek_in_seconds,
  use_live_button,
  is_forward_rewind_used
}: {
  seek_in_seconds: number;
  use_live_button?: boolean;
  is_forward_rewind_used?: boolean;
}) => {
  const {
    stream,
    isFollowing
  } = useStreamStore.getState();
  if (!stream?.uid) return;
  const me = useAuthStore.getState().me;
  const wallet = useProfileStore.getState().wallet;
  const web_page_type = useAppStore.getState().pageInfo.page;
  const botInfo = useAppStore.getState().botInfo;
  const sourceTrackingInfo = useStreamStore.getState().unicornSourceTrackingInfoWhenVisitEventFired;
  const {
    initial_bitrate,
    manual_bitrate_change,
    stream_join_time_in_epoch,
    watch_duration_without_seekbar_used
  } = fetchEventData(stream?.uid || '');
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    timezone_offset,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  updateMetric(stream.uid, 'watch_duration_without_seekbar_used', 0);
  const fingerprint = localStorage.getItem('fingerprint');
  const isWebview = typeof window !== 'undefined' ? /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(window?.navigator?.userAgent) : false;
  const sourceEventProperties = getSourceEventProperties(sourceTrackingInfo);
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  const last_manual_bitrate = manual_bitrate_change[manual_bitrate_change.length - 1] || initial_bitrate;
  const common_events = {
    seek_bar_moved_to: Math.round(seek_in_seconds) || 0,
    video_watch_duration: Math.round(watch_duration_without_seekbar_used),
    used_live_button: !!use_live_button,
    is_forward_rewind_used: !!is_forward_rewind_used,
    session_id: sessionId,
    platform: 7,
    timestamp: Date.now(),
    time_zone: timezone_offset ? timezone_offset : undefined,
    category_name: stream?.game_name,
    streamer_type: stream?.streamer.user_type,
    initial_bitrate: initial_bitrate.toLowerCase() || 'auto',
    bitrate: last_manual_bitrate.toLowerCase() || 'auto',
    category_id: stream?.game_uid,
    streamer_name: stream?.streamer.username,
    is_followed: isFollowing,
    streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
    streamer_id: stream?.streamer.user_uid,
    stream_url: stream?.VODUrl,
    video_id: stream?.uid,
    web_page_type: web_page_type,
    web_platform: 'loco.gg',
    web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop'
  };
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: 'seekbar_used',
      props: {
        // Sending undefined for consistency with Mobile Device
        life_count: undefined,
        app_source: undefined,
        battery: undefined,
        app_version: undefined,
        version_name: undefined,
        gaid: undefined,
        carrier: undefined,
        animations: undefined,
        build_variant: undefined,
        country: undefined,
        abi: undefined,
        zhsm: undefined,
        zpsi: undefined,
        zpe: undefined,
        zsm: undefined,
        zir: undefined,
        is_in_portrait: undefined,
        // Above Sending undefined for consistency with Mobile Device
        bot_probable: botInfo?.bot,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        ...sourceEventProperties,
        ...common_events,
        source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
        source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
        source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined
      }
    }]
  };
  Amplitude.logEvent('seekbar_used', {
    ...common_events,
    seek_bar_moved_to: common_events.seek_bar_moved_to,
    video_watch_duration: common_events.video_watch_duration,
    used_live_button: common_events.used_live_button ? 'yes' : 'no',
    is_forward_rewind_used: common_events.is_forward_rewind_used ? 'yes' : 'no',
    ...sourceEventProperties
  });
  if (isProduction()) {
    unicornEvents(value);
  }
};
export const logWatchVideoEvent = ({
  stream,
  duration,
  theatreModeUsed,
  fullscreenModeUsed,
  theatreModeWatchCount,
  fullscreenModeWatchCount,
  chatToggleCount,
  sourceEventProperties,
  streamJoinTime,
  commonEvents,
  buffered_time_in_seconds,
  gold_earned,
  times_buffered,
  playerDescriptionExpandCount,
  stream_web_page_type
}: LogWatchVideoEvent): void => {
  const wallet = useProfileStore.getState().wallet;
  const me = useAuthStore.getState().me;
  const {
    playback_speed = [],
    player_pip_expand = false,
    player_play_pause_toggle_count = 0,
    player_seek_bar_count = 0,
    player_keyboard_seek_count = 0,
    trick_play_mode_count,
    trick_play_mode_duration: _trick_play_mode_duration,
    player_report_content = false,
    player_report_content_count = 0,
    player_report_content_type = [],
    player_pip_close_button_clicked = false,
    web_perf_playback_api_time_ms,
    web_perf_render_ui_time_ms,
    web_perf_stream_start_time_ms,
    initial_bitrate,
    manual_bitrate_change,
    stream_join_time_in_epoch,
    mute_duration: _mute_duration,
    mute_toggle_count
  } = fetchEventData(stream?.uid || '');
  const mute_duration = Math.round(_mute_duration);
  const trick_play_mode_duration = Math.round(_trick_play_mode_duration);
  const sessionId = `${stream?.uid}-${stream_join_time_in_epoch}`;
  const last_manual_bitrate = manual_bitrate_change[manual_bitrate_change.length - 1] || initial_bitrate;
  const botInfo = useAppStore.getState().botInfo;
  // const view_type_on_web = usePlayerEvents.getState().view_type_on_web
  const vodSpecificProperities = stream?.status !== 20 ? {
    is_replay_chat_enabled: stream?.status === 30 && stream.started_at > 1645209000000 ? true : false,
    video_upload_date: dayjs(stream?.updated_at).format('DD MM YYYY'),
    vod_length: stream?.duration,
    vod_type: stream?.is_uploaded ? 'uploaded' : 'converted_from_stream'
  } : {
    stream_join_time: streamJoinTime
  };
  const isFollowingProperty = me?.user_uid && {
    is_followed: useStreamStore.getState().isFollowing
  };
  const is_trickplay_enabled = !!stream?.trick_play_url;
  const userVipType = useProfileStore.getState().vipType;
  commonEvents = {
    ...commonEvents,
    is_content_mature: stream?.has_mature_content ? true : false,
    mute_duration,
    mute_toggle_count,
    session_id: sessionId,
    initial_bitrate: initial_bitrate.toLowerCase() || 'auto',
    bitrate: last_manual_bitrate.toLowerCase() || 'auto',
    stream_name: stream?.title,
    stream_url: stream?.VODUrl,
    gold_earned: gold_earned,
    buffered_time_in_seconds: buffered_time_in_seconds,
    times_buffered: times_buffered,
    streamer_id: stream?.streamer.user_uid,
    video_watch_duration: duration,
    streamer_name: stream?.streamer.username,
    category_id: stream?.game_uid,
    video_id: stream?.uid,
    video_view_count: stream?.currrentViews,
    category_name: stream?.game_name,
    streamer_type: stream?.streamer.user_type,
    theatre_mode_watch_duration: theatreModeWatchCount,
    full_screen_watch_duration: fullscreenModeWatchCount,
    times_chat_collapsed: chatToggleCount.chatCollapsed,
    times_chat_reopened: chatToggleCount.chatReOpened,
    playback_speed: playback_speed,
    player_play_pause_toggle_count,
    theatre_mode_used: theatreModeUsed ? 'yes' : 'no',
    full_screen_used: fullscreenModeUsed ? 'yes' : 'no',
    player_pip_expand: player_pip_expand ? 'yes' : 'no',
    is_trickplay_enabled: is_trickplay_enabled ? 'yes' : 'no',
    times_hovered: trick_play_mode_count,
    hovered_time_in_seconds: trick_play_mode_duration,
    player_pip_close_button_clicked: player_pip_close_button_clicked ? 'yes' : 'no',
    player_report_content: player_report_content ? 'yes' : 'no',
    player_seek_count: player_seek_bar_count + player_keyboard_seek_count,
    player_seek_bar_count,
    player_keyboard_seek_count,
    player_report_content_count,
    player_report_content_type,
    video_tags: stream?.tags,
    streamer_category: stream?.streamer.user_type === STREAMER_TYPE.partner ? 'partner ' : stream?.streamer.user_type === STREAMER_TYPE.organic ? 'organic' : stream?.streamer.user_type === STREAMER_TYPE.admin ? 'admin' : stream?.streamer.user_type === STREAMER_TYPE.premium ? 'premium' : stream?.streamer.user_type === STREAMER_TYPE.viewer ? 'viewer' : 'viewer',
    web_page_type: stream_web_page_type,
    player_description_expand_count: playerDescriptionExpandCount,
    ...sourceEventProperties,
    ...vodSpecificProperities,
    ...isFollowingProperty,
    web_perf_playback_api_time_ms,
    web_perf_render_ui_time_ms,
    web_perf_stream_start_time_ms,
    web_perf_total_time_ms: web_perf_playback_api_time_ms + web_perf_render_ui_time_ms + web_perf_stream_start_time_ms,
    is_streamer_vip: stream?.is_vip ? true : false,
    is_vip: userVipType || 'non-vip'
  };
  commonEvents = filterDesktopSpecificFields(commonEvents);
  Amplitude.logEvent(stream?.status === 20 ? 'watch_stream' : 'watch_vod', {
    ...commonEvents
  });
  dLog.log(`Amplitude watch_* Event: Fired: YYYYYYY`, Date.now());
  const fingerprint = localStorage.getItem('fingerprint');
  const isWebview = typeof window !== 'undefined' ? /(Version\/\d+.*\/\d+.0.0.0 Mobile|; ?wv|(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari))/i.test(window?.navigator?.userAgent) : false;
  const {
    is_charging,
    manufacturer,
    network_speed_kbps,
    network_type,
    os_name,
    os_version,
    timezone_offset,
    screen,
    browser,
    browserVersion,
    browserMajorVersion,
    cookies
  } = useAppStore.getState().deviceInfo;
  const value = {
    context: {
      device_id: fingerprint,
      env: IS_STAGING ? 'staging' : 'prod',
      gold_count: me?.username ? wallet.gold : undefined,
      is_charging: is_charging,
      logged_in: me?.username ? true : false,
      manufacturer: manufacturer,
      network_speed_kbps: network_speed_kbps,
      network_type: network_type,
      origin: 'client',
      os_name: os_name,
      os_version: os_version,
      browser_screen: screen,
      browser_name: browser,
      browser_version: browserVersion,
      browser_major_version: browserMajorVersion,
      browser_cookies_enable: cookies,
      platform: 7,
      streamer: me?.can_stream ? true : false,
      user_uid: me?.user_uid ? me.user_uid : undefined,
      username: me?.username ? me.username : undefined
    },
    events: [{
      event_name: stream?.status === 20 ? 'watch_stream' : 'watch_vod',
      props: {
        session_id: sessionId,
        initial_bitrate: initial_bitrate.toLowerCase() || 'auto',
        bitrate: last_manual_bitrate.toLowerCase(),
        bot_probable: botInfo?.bot,
        bot_kind: botInfo?.bot ? botInfo?.botKind : undefined,
        bk_device_id: fingerprint ? fingerprint : undefined,
        bk_user_id: me?.user_uid ? me.user_uid : undefined,
        platform: 7,
        gold_earned: gold_earned,
        buffered_time_in_seconds: buffered_time_in_seconds,
        times_buffered: times_buffered,
        web_device_type: isMobile() ? isWebview ? 'webview' : 'mobile_browser' : 'desktop',
        time_zone: timezone_offset ? timezone_offset : undefined,
        thumbnail_url: stream?.thumbnail_url_small,
        timestamp: Date.now(),
        stream_url: stream?.VODUrlV2,
        streamer_name: stream?.streamer?.username,
        streamer_type: stream?.streamer.user_type,
        ...commonEvents,
        is_trickplay_enabled: is_trickplay_enabled,
        times_hovered: trick_play_mode_count,
        hovered_time_in_seconds: trick_play_mode_duration,
        theatre_mode_used: theatreModeUsed ? true : false,
        full_screen_used: fullscreenModeUsed ? true : false,
        player_pip_expand: player_pip_expand ? true : false,
        player_pip_close_button_clicked: player_pip_close_button_clicked ? true : false,
        player_report_content: player_report_content ? true : false,
        source_component_highlighted: sourceEventProperties.source_component_highlighted === 'yes' ? true : false,
        source_sidenav: sourceEventProperties.source_sidenav === 'yes' ? true : false,
        source_view_all_in_source_component: sourceEventProperties.hasOwnProperty('source_view_all_in_source_component') ? sourceEventProperties?.source_view_all_in_source_component === 'yes' ? true : false : undefined,
        web_platform: 'loco.gg'
      }
    }]
  };
  if (isProduction()) {
    dLog.log(`Unicorn watch_* Event: Fired:`, Date.now(), value);
    unicornEvents(value);
  } else {
    dLog.log(`Unicorn watch_* Event: Not Fired:`);
  }
};
type LeaderboardAmplitudeLog = {
  pageInfo: IPageInfo;
  timeSpentOnLeaderboard: number;
  me: MeInterface | null;
  data: StateDataType;
  leaderboardDetails: leaderboardDetailsType;
  sourceEventProperties: SourceEventPropertiesType;
};
export function getLeaderboardAmplitudeLog({
  pageInfo,
  timeSpentOnLeaderboard,
  me,
  data,
  leaderboardDetails,
  sourceEventProperties
}: LeaderboardAmplitudeLog) {
  return {
    source: 'streamer',
    time_spent: timeSpentOnLeaderboard,
    streamer_id: pageInfo.streamerId,
    streamer_name: pageInfo.streamerName,
    category_id: pageInfo.categoryId,
    category_name: pageInfo.categoryName,
    logged_in: me ? 'true' : 'false',
    rank_daily: data?.daily?.today?.yourRanking?.rank ?? 0,
    rank_weekly: data?.weekly?.today?.yourRanking?.rank ?? 0,
    rank_monthly: data?.monthly?.today?.yourRanking?.rank ?? 0,
    current_daily_clicks: leaderboardDetails?.daily?.current,
    current_weekly_clicks: leaderboardDetails?.weekly?.current,
    current_monthly_clicks: leaderboardDetails?.monthly?.current,
    previous_daily_clicks: leaderboardDetails?.daily?.previous,
    previous_weekly_clicks: leaderboardDetails?.weekly?.previous,
    previous_monthly_clicks: leaderboardDetails?.monthly?.previous,
    web_page_type: pageInfo?.page,
    ...sourceEventProperties
  };
}
type LogWatchClipEventProps = {
  sourceEventProperties: SourceEventPropertiesType;
  streamer_name: string;
  streamer_id: string;
  video_id: string;
  video_tags: Array<string>;
  category_name: string;
  category_id: string;
  video_watch_duration: number;
  video_view_count: number;
  clip_length: number;
  clip_number: number;
  video_upload_date: string;
  pageInfo: IPageInfo;
  clip_title: string;
  is_newbie_clip: boolean;
  on_mute: boolean;
  clip_replay_count?: number;
};
export const LogWatchClipEvent = ({
  sourceEventProperties,
  streamer_name,
  streamer_id,
  video_id,
  video_tags,
  category_name,
  category_id,
  video_watch_duration,
  video_view_count,
  clip_length,
  clip_number,
  video_upload_date,
  pageInfo,
  clip_title,
  is_newbie_clip,
  on_mute,
  clip_replay_count = 0
}: LogWatchClipEventProps) => {
  Amplitude.logEvent('watch_clip', {
    ...sourceEventProperties,
    web_page_type: pageInfo.page,
    streamer_name,
    streamer_id,
    video_id,
    video_tags,
    video_view_count,
    category_name,
    category_id,
    clip_length,
    video_watch_duration,
    clip_number,
    video_upload_date,
    streamer_type: pageInfo.streamerType,
    streamer_category: pageInfo.streamerCategory,
    clip_title,
    is_newbie_clip,
    on_mute,
    clip_replay_count
  });
};