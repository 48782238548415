function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import React from 'react';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
interface MatureContentBannerProps {
  thumbnailUrl: string | null;
  onAccept: () => void;
  onReject: () => void;
}
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1toifsr",
  styles: "position:relative;display:flex;height:100%;width:100%;align-items:center;justify-content:center"
} : {
  name: "bndzr3-MatureContentBanner",
  styles: "position:relative;display:flex;height:100%;width:100%;align-items:center;justify-content:center;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "1ob7dfu",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px"
} : {
  name: "3i13nw-MatureContentBanner",
  styles: "position:absolute;top:0px;right:0px;bottom:0px;left:0px;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "15r8axr",
  styles: "position:relative;z-index:10;width:100%;max-width:450px;padding-left:1rem;padding-right:1rem;@media (min-width: 640px){padding-left:0px;padding-right:0px;}"
} : {
  name: "19q61hp-MatureContentBanner",
  styles: "position:relative;z-index:10;width:100%;max-width:450px;padding-left:1rem;padding-right:1rem;@media (min-width: 640px){padding-left:0px;padding-right:0px;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1vcd41q",
  styles: "text-align:center;@media (min-width: 640px){display:flex;align-items:center;text-align:left;}"
} : {
  name: "12uloq8-MatureContentBanner",
  styles: "text-align:center;@media (min-width: 640px){display:flex;align-items:center;text-align:left;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1nri55u",
  styles: "margin-left:auto;margin-right:auto;margin-bottom:1rem;height:64px;width:64px;@media (min-width: 640px){margin-left:0px;margin-right:1.5rem;margin-bottom:0px;height:118px;width:118px;}"
} : {
  name: "11x6ihi-MatureContentBanner",
  styles: "margin-left:auto;margin-right:auto;margin-bottom:1rem;height:64px;width:64px;@media (min-width: 640px){margin-left:0px;margin-right:1.5rem;margin-bottom:0px;height:118px;width:118px;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1kzq5ms",
  styles: "height:100%;width:100%"
} : {
  name: "hjsx04-MatureContentBanner",
  styles: "height:100%;width:100%;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "12n6jp",
  styles: "flex:1 1 0%"
} : {
  name: "gi90o5-MatureContentBanner",
  styles: "flex:1 1 0%;label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "94le37",
  styles: "margin-bottom:0.5rem;font-size:10px;font-weight:700;@media (min-width: 640px){font-size:1rem;line-height:1.5rem;}"
} : {
  name: "flruz5-MatureContentBanner",
  styles: "margin-bottom:0.5rem;font-size:10px;font-weight:700;@media (min-width: 640px){font-size:1rem;line-height:1.5rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1wp8ce4",
  styles: "margin-bottom:1rem;@media (min-width: 640px){margin-bottom:1.5rem;}"
} : {
  name: "6foyiw-MatureContentBanner",
  styles: "margin-bottom:1rem;@media (min-width: 640px){margin-bottom:1.5rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "1btu1tu",
  styles: "font-size:10px;@media (min-width: 640px){font-size:0.875rem;line-height:1.25rem;}"
} : {
  name: "wc2d9k-MatureContentBanner",
  styles: "font-size:10px;@media (min-width: 640px){font-size:0.875rem;line-height:1.25rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "1btu1tu",
  styles: "font-size:10px;@media (min-width: 640px){font-size:0.875rem;line-height:1.25rem;}"
} : {
  name: "wc2d9k-MatureContentBanner",
  styles: "font-size:10px;@media (min-width: 640px){font-size:0.875rem;line-height:1.25rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "rusygd",
  styles: "display:flex;flex-direction:row;justify-content:center;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1rem * var(--tw-space-x-reverse));margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)));}@media (min-width: 640px){justify-content:flex-start;}"
} : {
  name: "sd2rks-MatureContentBanner",
  styles: "display:flex;flex-direction:row;justify-content:center;> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(1rem * var(--tw-space-x-reverse));margin-left:calc(1rem * calc(1 - var(--tw-space-x-reverse)));}@media (min-width: 640px){justify-content:flex-start;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "1990ew2",
  styles: "height:20px;width:75px;border-radius:0.25rem;font-size:8px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));transition-property:color, background-color, border-color, text-decoration-color, fill, stroke;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;:hover{--tw-bg-opacity:0.8;}@media (min-width: 640px){height:33px;width:122px;font-size:0.875rem;line-height:1.25rem;}"
} : {
  name: "1ly7ua7-MatureContentBanner",
  styles: "height:20px;width:75px;border-radius:0.25rem;font-size:8px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));transition-property:color, background-color, border-color, text-decoration-color, fill, stroke;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;:hover{--tw-bg-opacity:0.8;}@media (min-width: 640px){height:33px;width:122px;font-size:0.875rem;line-height:1.25rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref14 = process.env.NODE_ENV === "production" ? {
  name: "1990ew2",
  styles: "height:20px;width:75px;border-radius:0.25rem;font-size:8px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));transition-property:color, background-color, border-color, text-decoration-color, fill, stroke;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;:hover{--tw-bg-opacity:0.8;}@media (min-width: 640px){height:33px;width:122px;font-size:0.875rem;line-height:1.25rem;}"
} : {
  name: "1ly7ua7-MatureContentBanner",
  styles: "height:20px;width:75px;border-radius:0.25rem;font-size:8px;--tw-text-opacity:1;color:rgb(255 255 255 / var(--tw-text-opacity));transition-property:color, background-color, border-color, text-decoration-color, fill, stroke;transition-timing-function:cubic-bezier(0.4, 0, 0.2, 1);transition-duration:150ms;:hover{--tw-bg-opacity:0.8;}@media (min-width: 640px){height:33px;width:122px;font-size:0.875rem;line-height:1.25rem;};label:MatureContentBanner;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const MatureContentBanner: React.FC<MatureContentBannerProps> = ({
  thumbnailUrl,
  onAccept,
  onReject
}) => {
  return _jsxs("div", {
    css: _ref,
    style: {
      backgroundImage: `url(${thumbnailUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    children: [_jsx("div", {
      css: _ref2,
      style: {
        backdropFilter: 'blur(8px) brightness(30%)'
      }
    }), _jsx("div", {
      css: _ref3,
      children: _jsxs("div", {
        css: _ref4,
        children: [_jsx("div", {
          css: _ref5,
          children: _jsx("img", {
            src: "/static/images/mature_content.png",
            alt: "Mature content warning",
            css: _ref6
          })
        }), _jsxs("div", {
          css: _ref7,
          children: [_jsx("h2", {
            css: _ref8,
            children: "18+ Mature content warning"
          }), _jsxs("div", {
            css: _ref9,
            children: [_jsx("p", {
              css: _ref10,
              children: "This content has been marked as mature. "
            }), _jsx("p", {
              css: _ref11,
              children: "You must be 18 or older to view this content."
            })]
          }), _jsxs("div", {
            css: _ref12,
            children: [_jsx("button", {
              onClick: onReject,
              css: _ref13,
              style: {
                background: 'rgba(56, 56, 56, 1)'
              },
              children: "Go back"
            }), _jsx("button", {
              onClick: onAccept,
              css: _ref14,
              style: {
                background: 'rgba(81, 31, 255, 1)'
              },
              children: "Yes, I am 18+"
            })]
          })]
        })]
      })
    })]
  });
};
export default MatureContentBanner;