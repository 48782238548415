import { updateMetric } from '@stores/usePlayerEvents';
import { getCookie } from '@utils/cookies';
import { instrumentedFetch } from '@utils/prometheus/instrumentedFetch';
import { gameStreamsResponseInterface, StreamInterface, TopGameInterface } from '../types';
import { fetchWithAllErrorHandle } from './apiWrapper';
export enum FeedTypeENUM {
  HOME = 'home',
  CATEGORIES = 'categories',
  STREAMERS = 'streamers',
  TRENDING = 'trending',
  VIDEOS = 'videos',
  LIVESTREAMS = 'live',
  ACADEMY = 'academy',
  VIDEOS_IN_FOCUS = 'videos_in_focus',
  TRENDING_VIDEOS = 'trending_videos',
}
export enum SidebarFeedTypeENUM {
  HOME = 'home',
  CATEGORIES = 'category',
  STREAMER = 'streamer',
  STREAM = 'stream',
}
export enum SubReceipeFeedTypeENUM {
  VIDEOS = 'WEB_videos_home',
  BGMI = 'get_trending_videos_bgmi',
  GTA5 = 'get_trending_videos_gta',
  VALORANT = 'get_trending_videos_valorant',
  FREEFIRE = 'get_trending_videos_freefire',
}
export const getStreamPageData = async ({
  streamerID,
  limit,
  offset,
  token
}: {
  streamerID: string;
  token: string;
  limit?: string;
  offset?: string;
}) => {
  const url = new URL(`${process.env.IVORY_SERVER_URL_V3}/homepage/sub_recipe/`);

  //@ts-ignore
  url.search = new URLSearchParams({
    searchQuery: streamerID,
    limit: limit || '16',
    offset: offset || '0',
    r_id: 'STREAMER_profile_V2'
  });

  //@ts-ignore
  const StreamerProfileResponse = await fetch(url, {
    method: 'GET',
    //@ts-ignore
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      'X-PLATFORM': 7
    }
  });
  const StreamerProfile = await StreamerProfileResponse.json();
  return StreamerProfile;
};
export const getCarousalData = async ({
  type,
  limit,
  offset,
  token
}: {
  type: string;
  limit: string;
  offset: string;
  token: string;
}) => {
  const url = new URL(`${process.env.IBIZA_SERVER_URL_V3}/subrecipe/`);

  //@ts-ignore
  url.search = new URLSearchParams({
    type: type,
    limit: limit,
    offset: offset,
    r_id: 'WEB_home_carousel_ibiza'
  });

  //@ts-ignore
  const CarousalResponse = await fetch(url, {
    method: 'GET',
    //@ts-ignore
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-PLATFORM': 7
    }
  });
  const Carousal = await CarousalResponse.json();
  return Carousal;
};
export const getFeedData = async ({
  type,
  limit,
  offset,
  token
}: {
  type: string;
  limit: string;
  offset: string;
  token: string;
}) => {
  const url = new URL(`${process.env.SERVER_URL_V2}/homepage/`);
  //@ts-ignore
  url.search = new URLSearchParams({
    type: type,
    limit: limit,
    offset: offset
  });

  //@ts-ignore
  const FeedResponse = await instrumentedFetch(url, {
    method: 'GET',
    //@ts-ignore
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      'X-PLATFORM': 7
    }
  });
  const Feed = await FeedResponse.json();
  return Feed;
};
export const getSidebarFeedData = async ({
  type,
  limit,
  offset,
  token
}: {
  type: string;
  limit: string;
  offset: string;
  token: string;
}) => {
  const url = new URL(`${process.env.IBIZA_SERVER_URL_V3}/sidenav/`);
  //@ts-ignore
  url.search = new URLSearchParams({
    type: type,
    limit: limit,
    offset: offset
  });
  const result = await fetchWithAllErrorHandle
  //@ts-ignore
  .url(url).headers({
    'Content-Type': 'application/json;charset=utf-8',
    'X-PLATFORM': '7'
  }).auth(`Bearer ${token}`).get().error(404, () => {
    return [];
  }).error(403, () => {
    return [];
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getStreamerProfileFromUsername = async ({
  streamerUsername
}:
// token,
{
  streamerUsername: string;
  token: string;
}) => {
  const streamerProfileFromUsernameResponse = await fetch(`${process.env.SERVER_URL_V1}/username_to_useruid/?username=${encodeURIComponent(streamerUsername)}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-Platform': '7'
    }
  });
  // console.log(streamerProfileFromUsernameResponse)
  if (!streamerProfileFromUsernameResponse.ok) {
    // try {
    //   await getStreamerProfile({ streamerID: streamerUsername, token })
    //   return streamerUsername
    // } catch {
    return null;
    // }
  }

  const streamerProfileId = await streamerProfileFromUsernameResponse.json();
  return streamerProfileId.user_uid;
};
export const getStreamerProfile = async ({
  streamerID,
  token
}: {
  streamerID: string;
  token: string;
}) => {
  const streamerProfileResponse = await fetch(`${process.env.SERVER_URL_V2}/profile/${streamerID}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  if (!streamerProfileResponse.ok) {
    return null;
    // throw { status: streamerProfileResponse.status }
  }

  const streamerProfile = await streamerProfileResponse.json();
  return streamerProfile.data;
};
export const getAllVidoesOfStreamer = async ({
  streamerID,
  token,
  next = false,
  nextUrl,
  limit = '30'
}: {
  streamerID: string;
  token: string;
  next?: boolean;
  nextUrl?: string | null;
  limit?: string;
}) => {
  const allVidoesofStreamerResponse = await fetch(next ? `${process.env.BASE_SERVER_URL}${nextUrl}` : `${process.env.SERVER_URL}/profile/${streamerID}/streams/?limit=${limit}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  const allVideosofStreamer = await allVidoesofStreamerResponse.json();
  const liveStream = ((allVideosofStreamer?.results as StreamInterface[]) || [])?.filter(stream => stream.status === 20)
  // Sort to pick latest video at top
  ?.sort((a, b) => b.created_at - a.created_at);
  const allVODS = ((allVideosofStreamer?.results as StreamInterface[]) || [])?.filter(stream => stream.status !== 20);
  return {
    allVODS: allVODS,
    liveStream: liveStream?.length > 0 ? liveStream[0] : null,
    videoNext: allVideosofStreamer?.next ? allVideosofStreamer?.next : null,
    videosCount: allVideosofStreamer?.count ? allVideosofStreamer?.count : null
  };
};
export const getGameDetails = async ({
  gameID,
  token
}: {
  gameID: string;
  token: string;
}) => {
  const gameResponse = await fetch(`${process.env.SERVER_URL}/games/${gameID}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  const game: TopGameInterface = await gameResponse.json();
  return game;
};
export const getStreamsBasedOnTypeOfTheGame = async ({
  gameID,
  token,
  type,
  limit = '20',
  fetchingNextVideos = false,
  nextVideoUrl
}: {
  gameID: string;
  token: string;
  type: string;
  limit?: string;
  fetchingNextVideos?: boolean;
  nextVideoUrl?: string;
}) => {
  const url = fetchingNextVideos ? `${process.env.BASE_SERVER_URL}${nextVideoUrl}` : `${process.env.SERVER_URL}/games/streamsbystatus/${gameID}/?type=${type}&limit=${limit}`;
  const streamsResponse = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  const gameStreams: gameStreamsResponseInterface = await streamsResponse.json();
  return {
    gameStreams: gameStreams.results ? gameStreams.results : [],
    gameStreamsNext: gameStreams.next ? gameStreams.next : null,
    gameStreamsPrev: gameStreams.previous ? gameStreams.previous : null,
    gameStreamsCount: gameStreams.count ? gameStreams.count : null
  };
};
export const getStreamersOfGame = async ({
  gameID,
  token,
  limit = '50',
  offset = ''
}: {
  gameID: string;
  token: string;
  limit?: string;
  offset?: string;
}) => {
  const StreamersForGameResponse = await fetch(`${process.env.SERVER_URL}/games/${gameID}/streamers/?limit=${limit}&offset=${offset}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  const allStreamersForGameResult = await StreamersForGameResponse.json();
  return {
    gameStreamers: allStreamersForGameResult?.results ? allStreamersForGameResult.results : [],
    gameStreamersNext: allStreamersForGameResult?.next ? allStreamersForGameResult.next : null
  };
};
export const getShortUrl = async ({
  urlObj,
  token
}: {
  urlObj: any;
  token: string;
}) => {
  const resultJson = await fetch(`${process.env.SERVER_URL}/create_short_url/
  `, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    body: JSON.stringify(urlObj)
  });
  const result = await resultJson.json();
  return {
    short_url: result.short_url ? result.short_url : null
  };
};
export const getDeepShortUrl = async ({
  url,
  token
}: {
  url: string;
  token: string;
}) => {
  const resultJson = await fetch(`${process.env.SERVER_URL_V1}/dynamic_share_link/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Platform': '7',
      Authorization: token
    },
    body: JSON.stringify({
      url
    })
  });
  const result = await resultJson.json();
  return {
    share_link: result.share_link ? result.share_link : null
  };
};
export const getPlayBackUrl = async (streamId: string, isCountMetric?: boolean) => {
  const URL = `${process.env.DRM_URL}/streams/playback/?stream_uid=${streamId}`;
  const token = getCookie('access_token');
  const startTime = Date.now();
  let endTime;
  let apiResponse = null;
  try {
    if (isCountMetric) {
      // only update for current stream
      updateMetric(streamId, 'web_perf_playback_api_time_ms', 0);
    }
    const playbackDetails = await fetch(`${URL}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: token!
      }
    });
    const textApiResponse = await playbackDetails.text();
    try {
      apiResponse = JSON.parse(textApiResponse);
    } catch (error) {
      apiResponse = null;
    }
    // !200 response from DRM api
    if (!playbackDetails.ok) {
      throw {
        code: playbackDetails.status,
        message: textApiResponse || ''
      };
    }
    endTime = Date.now();
    if (isCountMetric) {
      updateMetric(streamId, 'web_perf_playback_api_time_ms', endTime - startTime);
    }
    return {
      status: playbackDetails.status,
      data: apiResponse
    };
  } catch (error) {
    endTime = Date.now();
    if (isCountMetric) {
      updateMetric(streamId, 'web_perf_playback_api_time_ms', endTime - startTime);
    }
    return {
      // @ts-ignore
      status: error?.code,
      // @ts-ignore
      error_message: error?.message,
      data: apiResponse
    };
  }
};
export const getReportActions = async () => {
  const URL = `${process.env.SERVER_URL}/report/actions/`;
  const token = getCookie('access_token');
  const result = await fetchWithAllErrorHandle.url(URL).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).auth((token as string)).get().error(404, () => {
    return {};
  }).error(403, () => {
    return {};
  }).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const reportStreamAction = async (streamId: string, flag_uid: string) => {
  const URL = `${process.env.SERVER_URL}/report/stream/`;
  const token = getCookie('access_token');
  const params = {
    streamId: streamId,
    flag_uid: flag_uid
  };
  const result = await fetchWithAllErrorHandle.url(URL).auth((token as string)).headers({
    'Content-Type': 'application/json;charset=utf-8'
  }).post(params).json(json => {
    return json;
  }).catch(error => {
    return error;
  });
  return result;
};
export const getNewFeedData = async ({
  type,
  limit,
  offset,
  token,
  id
}: {
  type: string;
  limit: string;
  offset: string;
  token: string;
  id?: string;
}) => {
  const url = new URL(`${process.env.IVORY_SERVER_URL_V3}/homepage/sub_recipe/`);
  //@ts-ignore
  url.search = new URLSearchParams({
    type: type,
    limit: limit,
    offset: offset,
    r_id: id
  });

  //@ts-ignore
  const FeedResponse = await fetch(url, {
    method: 'GET',
    //@ts-ignore
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
      'X-PLATFORM': 7
    }
  });
  const Feed = await FeedResponse.json();
  return Feed;
};
export const getVidoesOfStreamer = async ({
  streamerID,
  token,
  next = false,
  nextUrl,
  type,
  limit = '20'
}: {
  streamerID: string;
  token: string;
  next?: boolean;
  nextUrl?: string | null;
  type?: string;
  limit?: string;
}) => {
  const url = next ? `${process.env.BASE_SERVER_URL}${nextUrl}` : `${process.env.SERVER_URL_V2}/profile/${streamerID}/streams/?type=${type}&offset=&limit=${limit}`;
  const videosOfStreamerResponse = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  });
  const videosofStreamer = await videosOfStreamerResponse.json();
  return {
    allVODS: videosofStreamer?.results ? videosofStreamer?.results : [],
    videoNext: videosofStreamer?.next ? videosofStreamer?.next : null,
    videosCount: videosofStreamer?.count ? videosofStreamer?.count : null
  };
};
export const getSubReceipeFeedData = async ({
  r_id,
  limit,
  token,
  next,
  nextUrl
}: {
  r_id?: string;
  limit?: string;
  token: string;
  next?: boolean;
  nextUrl?: string | null;
}) => {
  try {
    const url = next ? `${process.env.BASE_SERVER_URL}${nextUrl}` : `${process.env.IVORY_SERVER_URL_V3}/homepage/sub_recipe/?r_id=${r_id}&limit=${limit}`;
    const result = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });
    const data = await result.json();
    return data;
  } catch (err: any) {
    return null;
  }
};
export const getProductIdOffline = async ({
  token
}: {
  token: string;
}) => {
  try {
    const result = await fetch(`${process.env.BASE_STORE_URL}/public/bundle/vip/info/v1/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });
    if (result.ok) {
      const data = await result.json();
      return data;
    } else {
      throw new Error('Failed to fetch data from the server');
    }
  } catch (err: any) {
    return null;
  }
};
export const getUserVipStreamerInfo = async ({
  productId,
  token
}: {
  productId: string;
  token: string;
}) => {
  try {
    const result = await fetch(`${process.env.BASE_SERVER_URL}/v1/leaderboard/products/${productId}/streamer_info/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token
      }
    });
    if (result.ok) {
      const data = await result.json();
      return data;
    } else {
      throw new Error('Failed to fetch data from the server');
    }
  } catch (err: any) {
    return null;
  }
};