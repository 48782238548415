import { GENDERS } from 'src/constants/profile';
import { MeInterface, STREAMER_TYPE } from '../../types';
import Amplitude_v2 from './amplitudeInstance';
const getStreamerCategory = (streamerType: number) => {
  const match = Object.entries(STREAMER_TYPE).find(([, value]) => value === streamerType);
  return match ? match[0] : undefined;
};
const UpdateAmplitudeUserHOC = (amp: Amplitude_v2) => {
  const updateAmplitudeUser = (me: MeInterface) => {
    const userProperties: any = {};
    userProperties['username'] = me?.username;
    userProperties['userId'] = me?.user_uid;
    userProperties['user_bio'] = me?.bio;
    userProperties['name'] = me?.full_name;
    userProperties['is_streamer'] = me?.can_stream ? true : false;
    userProperties['streamer_category'] = getStreamerCategory(me?.user_type);
    userProperties['streamer_type'] = me?.user_type;
    userProperties['user_tags'] = me?.profile_tags?.map((el: any) => el.display_name);
    userProperties['gender'] = me?.gender ? GENDERS[me?.gender] : GENDERS[0];
    userProperties['email'] = me?.social_link_info.gmail;
    userProperties['phone_number'] = me?.phone;
    userProperties['Identity'] = me?.user_uid;
    amp.setUserId(me?.user_uid ?? null);
    amp.setUserProperties(userProperties);
  };
  const resetAmplitudeUser = async (params?: {
    regenerate?: boolean;
  }) => {
    const userProperties: any = {};
    userProperties['username'] = null;
    userProperties['userId'] = null;
    userProperties['user_bio'] = null;
    userProperties['name'] = null;
    userProperties['is_streamer'] = null;
    userProperties['streamer_category'] = null;
    userProperties['streamer_type'] = null;
    userProperties['user_tags'] = null;
    userProperties['gender'] = null;
    userProperties['email'] = null;
    userProperties['phone_number'] = null;
    userProperties['Identity'] = null;
    amp.setUserId(null);
    amp.setUserProperties(userProperties);
    if (params?.regenerate === false) {
      return;
    } else {
      await amp.reset();
    }
  };
  return {
    updateAmplitudeUser,
    resetAmplitudeUser
  };
};
export default UpdateAmplitudeUserHOC;